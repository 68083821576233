@import url('./variables.css');

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color--gray-light);
}
