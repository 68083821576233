@import url('../../variables.css');

.container {
  height: var(--size--tile);
  position: absolute;
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
}

.container--backpack {
  background-image: url('/src/images/backpack.png');
}

.container--sack {
  background-image: url('/src/images/sack.png');
}

.container--trunk {
  background-image: url('/src/images/trunk.png');
}
