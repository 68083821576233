:root {
  --color--black: black;
  --color--blue: blue;
  --color--blue-light: lightblue;
  --color--brown: sienna;
  --color--gray: grey;
  --color--gray-light: #eee;
  --color--green-light: lightgreen;
  --color--red-light: lightcoral;
  --color--white: white;
  --color--yellow-light: lightyellow;

  --size--extra-small: 4px;
  --size--small: 8px;
  --size--normal: 16px;
  --size--tile: 80px;

  --z-index--map: 0;
  --z-index--tile: 5;
  --z-index--non-interactable: 10;
  --z-index--interactable: 15;
  --z-index--player: 20;
  --z-index--environment: 25;
  --z-index--fog-of-war: 30;
  --z-index--hud: 35;
  --z-index--control: 40;
  --z-index--menu: 45;
  --z-index--uniterruptable-menu: 50;
  --z-index--end-screen: 55;
}
