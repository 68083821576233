@import url('../../../variables.css');

.full-screen-menu {
  background-color: var(--color--black);
  color: var(--color--white);
  display: none;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-index--menu);
}

.full-screen-menu.open {
  display: flex;
}

.full-screen-menu .title {
  display: flex;
  flex-direction: row;
  padding: var(--size--small);
}

.full-screen-menu .title h1 {
  flex-grow: 1;
}

.full-screen-menu .title .close {
  background-color: transparent;
  border: none;
  color: var(--color--white);
  cursor: pointer;
  padding: var(--size--small);
}

.full-screen-menu .content {
  display: flex;
  flex-grow: 1;
}

.full-screen-menu .content .list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--size--small);
  padding: var(--size--normal);
}

.full-screen-menu .content .selected {
  padding: var(--size--normal);
}

@media (orientation: landscape) {
  .full-screen-menu .content {
    flex-direction: row;
  }

  .full-screen-menu .content .list {
    height: 100%;
    width: 50%;
  }

  .full-screen-menu .content .selected {
    height: 100%;
    width: 50%;
  }
}

@media (orientation: portrait) {
  .full-screen-menu .content {
    flex-direction: column;
  }

  .full-screen-menu .content .list {
    height: 50%;
    width: 100%;
  }

  .full-screen-menu .content .selected {
    height: 50%;
    width: 100%;
  }
}

.full-screen-menu .footer {
  padding: var(--size--small);
}
