@import url('../../../variables.css');

.fire__heat-radius__position {
  background-color: var(--color--red-light);
  height: var(--size--tile);
  opacity: 0.6;
  position: absolute;
  width: var(--size--tile);
  z-index: var(--z-index--non-interactable);
}
