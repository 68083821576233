@import url('../../variables.css');

.tile {
  height: var(--size--tile);
  position: absolute;
  width: var(--size--tile);
  z-index: var(--z-index--tile);
}

.tile.placeholder-empty {
  background-color: var(--color--blue-light);
}

.tile.rock-basic {
  background-image: url('/src/images/rock.png');
}

.tile.tree-birch {
  background-image: url('/src/images/tree.png');
}

.tile.water-basic {
  background-color: var(--color--blue);
}
