@import url('../../variables.css');

.interactable {
  height: var(--size--tile);
  position: absolute;
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
}

.interactable.bush-bearberry {
  background-image: url('/src/images/bush.png');
}

.interactable.carcass-deer-elk {
  background-image: url('/src/images/carcass-deer-elk.png');
}

.interactable.fuel-stick {
  background-image: url('/src/images/stick.png');
}

.interactable.starter-matches {
  background-image: url('/src/images/matches.png');
}

.interactable.tinder-newspaper {
  background-image: url('/src/images/newspaper.png');
}
