@import url('../../variables.css');

.animal {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: var(--size--tile);
  position: absolute;
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
}

.animal .deer-elk {
  background-image: url('/src/images/deer.png');
  height: var(--size--tile);
  width: var(--size--tile);
}

.animal .wolf-arctic {
  background-image: url('/src/images/wolf.png');
  height: var(--size--tile);
  width: var(--size--tile);
}
