@import url('../../variables.css');

.item {
  height: var(--size--tile);
  width: var(--size--tile);
}

.item.item__clothing__hat-beanie-wool {
  background-image: url('/src/images/beanie.png');
}

.item.item__clothing__jacket-denim {
  background-image: url('/src/images/denim-jacket.png');
}

.item.item__clothing__pants-jeans {
  background-image: url('/src/images/jeans.png');
}

.item.item__clothing__shirt-cotton {
  background-image: url('/src/images/tshirt.png');
}

.item.item__clothing__shoes-sneakers {
  background-image: url('/src/images/sneakers.png');
}

.item.item__clothing__socks-cotton {
  background-image: url('/src/images/socks.png');
}

.item.item__clothing__socks-wool {
  background-image: url('/src/images/socks-wool.png');
}

.item.item__clothing__underwear-cotton {
  background-image: url('/src/images/boxers.png');
}

.item.item__consumable__bearberry {
  background-image: url('/src/images/berries.png');
}

.item.item__consumable__bottle-water {
  background-image: url('/src//images/water-bottle.png');
}

.item.item__consumable__crisps-tomato {
  background-image: url('/src/images/crisps-tomato.png');
}

.item.item__consumable__meat-deer-cooked {
  background-image: url('/src/images/meat-deer-cooked.png');
}

.item.item__consumable__meat-deer-uncooked {
  background-image: url('/src/images/meat-deer-uncooked.png');
}

.item.item__fire__fuel-stick {
  background-image: url('/src/images/stick.png');
}

.item.item__fire__starter-matches {
  background-image: url('/src/images/matches.png');
}

.item.item__fire__tinder-newspaper {
  background-image: url('/src/images/newspaper.png');
}

.item.item__material__gut-default {
  background-image: url('/src/images/gut.png');
}

.item.item__material__hide-deer {
  background-image: url('/src/images/hide-deer.png');
}

.item.item__weapon__fists-bare {
  background-image: url('/src/images/fists-bare.png');
}

.item.item__weapon__knife-hunting {
  background-image: url('/src/images/knife-hunting.png');
}
