@import url('../../../variables.css');

#interaction-overlay {
  position: fixed;
  right: 0;
  top: var(--size--normal);
  z-index: var(--z-index--control);
}

#interaction-overlay .interaction-overlay__action--open {
  height: var(--size--tile);
  width: var(--size--tile);
}
