@import url('../../../variables.css');

.vital {
  background-color: var(--color--gray-light);
  border-radius: 50%;
  font-size: var(--size--tile);
  height: 1em;
  position: relative;
  width: 1em;
}

.vital:after {
  background-color: var(--color--white);
  border-radius: 50%;
  content: ' ';
  display: block;
  left: 0.08em;
  position: absolute;
  top: 0.08em;
  width: 0.84em;
  height: 0.84em;
}

.vital span {
  color: var(--color--gray);
  display: block;
  font-size: 0.2em;
  left: 0;
  line-height: 5em;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 5em;
  z-index: 1;
}

.vital .vital__value {
  clip: rect(0em, 1em, 1em, 0.5em);
  height: 1em;
  position: absolute;
  width: 1em;
}

.vital .vital__value .vital__value__bar {
  border: 0.08em solid var(--color--red-light);
  border-radius: 50%;
  clip: rect(0em, 0.5em, 1em, 0em);
  height: 0.84em;
  position: absolute;
  width: 0.84em;
}

.vital .vital__value .vital__value__fill {
  border: 0.08em solid var(--color--red-light);
  border-radius: 50%;
  clip: rect(0em, 0.5em, 1em, 0em);
  height: 0.84em;
  position: absolute;
  width: 0.84em;
}
