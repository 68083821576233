:root {
  --color--black: black;
  --color--blue: blue;
  --color--blue-light: lightblue;
  --color--brown: sienna;
  --color--gray: grey;
  --color--gray-light: #eee;
  --color--green-light: lightgreen;
  --color--red-light: lightcoral;
  --color--white: white;
  --color--yellow-light: lightyellow;
  --size--extra-small: 4px;
  --size--small: 8px;
  --size--normal: 16px;
  --size--tile: 80px;
  --z-index--map: 0;
  --z-index--tile: 5;
  --z-index--non-interactable: 10;
  --z-index--interactable: 15;
  --z-index--player: 20;
  --z-index--environment: 25;
  --z-index--fog-of-war: 30;
  --z-index--hud: 35;
  --z-index--control: 40;
  --z-index--menu: 45;
  --z-index--uniterruptable-menu: 50;
  --z-index--end-screen: 55;
}

#controls {
  bottom: var(--size--small);
  gap: var(--size--small);
  right: var(--size--small);
  z-index: var(--z-index--control);
  flex-direction: row;
  display: flex;
  position: fixed;
}

#controls .control {
  background-color: var(--color--gray-light);
  border-radius: var(--size--extra-small);
  border: 1px solid var(--color--gray);
  color: var(--color--black);
  cursor: pointer;
  padding: var(--size--extra-small);
  white-space: nowrap;
  font-family: monospace;
  font-weight: 700;
  box-shadow: 0 1px 1px #0003, inset 0 2px #ffffffb3;
}

.full-screen-menu {
  background-color: var(--color--black);
  color: var(--color--white);
  height: 100vh;
  width: 100vw;
  z-index: var(--z-index--menu);
  flex-direction: column;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.full-screen-menu.open {
  display: flex;
}

.full-screen-menu .title {
  padding: var(--size--small);
  flex-direction: row;
  display: flex;
}

.full-screen-menu .title h1 {
  flex-grow: 1;
}

.full-screen-menu .title .close {
  color: var(--color--white);
  cursor: pointer;
  padding: var(--size--small);
  background-color: #0000;
  border: none;
}

.full-screen-menu .content {
  flex-grow: 1;
  display: flex;
}

.full-screen-menu .content .list {
  gap: var(--size--small);
  padding: var(--size--normal);
  flex-flow: wrap;
  display: flex;
}

.full-screen-menu .content .selected {
  padding: var(--size--normal);
}

@media (orientation: landscape) {
  .full-screen-menu .content {
    flex-direction: row;
  }

  .full-screen-menu .content .list, .full-screen-menu .content .selected {
    height: 100%;
    width: 50%;
  }
}

@media (orientation: portrait) {
  .full-screen-menu .content {
    flex-direction: column;
  }

  .full-screen-menu .content .list, .full-screen-menu .content .selected {
    height: 50%;
    width: 100%;
  }
}

.full-screen-menu .footer {
  padding: var(--size--small);
}

.item {
  height: var(--size--tile);
  width: var(--size--tile);
}

.item.item__clothing__hat-beanie-wool {
  background-image: url("beanie.60ca5c26.png");
}

.item.item__clothing__jacket-denim {
  background-image: url("denim-jacket.d9c5cb28.png");
}

.item.item__clothing__pants-jeans {
  background-image: url("jeans.a664a8d6.png");
}

.item.item__clothing__shirt-cotton {
  background-image: url("tshirt.4a09d0cf.png");
}

.item.item__clothing__shoes-sneakers {
  background-image: url("sneakers.f61d58e1.png");
}

.item.item__clothing__socks-cotton {
  background-image: url("socks.27da94d2.png");
}

.item.item__clothing__socks-wool {
  background-image: url("socks-wool.27da94d2.png");
}

.item.item__clothing__underwear-cotton {
  background-image: url("boxers.91dddcad.png");
}

.item.item__consumable__bearberry {
  background-image: url("berries.b7d86bad.png");
}

.item.item__consumable__bottle-water {
  background-image: url("water-bottle.169bb354.png");
}

.item.item__consumable__crisps-tomato {
  background-image: url("crisps-tomato.15719bb4.png");
}

.item.item__consumable__meat-deer-cooked {
  background-image: url("meat-deer-cooked.b96454e1.png");
}

.item.item__consumable__meat-deer-uncooked {
  background-image: url("meat-deer-uncooked.5482f6e5.png");
}

.item.item__fire__fuel-stick {
  background-image: url("stick.5cc916a5.png");
}

.item.item__fire__starter-matches {
  background-image: url("matches.fc98ea18.png");
}

.item.item__fire__tinder-newspaper {
  background-image: url("newspaper.ba535e42.png");
}

.item.item__material__gut-default {
  background-image: url("gut.4b3f8c6a.png");
}

.item.item__material__hide-deer {
  background-image: url("hide-deer.322b0c38.png");
}

.item.item__weapon__fists-bare {
  background-image: url("fists-bare.5e762b1a.png");
}

.item.item__weapon__knife-hunting {
  background-image: url("knife-hunting.d9392880.png");
}

.inventory__item {
  cursor: pointer;
  height: var(--size--tile);
  width: var(--size--tile);
  background-color: #0000;
  border: none;
  position: relative;
}

.inventory__item .inventory__item__count {
  background-color: var(--color--red-light);
  border-radius: var(--size--small);
  height: var(--size--normal);
  right: var(--size--extra-small);
  top: var(--size--extra-small);
  width: var(--size--normal);
  font-weight: bold;
  display: block;
  position: absolute;
}

.movement-control {
  height: var(--size--tile);
  opacity: .6;
  width: var(--size--tile);
  z-index: var(--z-index--control);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.movement-control button {
  background-color: var(--color--black);
  border-radius: var(--size--small);
  cursor: pointer;
  height: var(--size--normal);
  width: var(--size--normal);
  border: none;
}

.movement-control.down {
  left: 0;
  top: var(--size--tile);
}

.movement-control.left {
  left: calc(var(--size--tile)  * -1);
  top: 0;
}

.movement-control.right {
  left: var(--size--tile);
  top: 0;
}

.movement-control.up {
  left: 0;
  top: calc(var(--size--tile)  * -1);
}

#player {
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--player);
  background-image: url("character.ae2fccc1.png");
  position: absolute;
}

.predator__aggro-radius__position {
  background-color: var(--color--red-light);
  height: var(--size--tile);
  opacity: .6;
  width: var(--size--tile);
  z-index: var(--z-index--non-interactable);
  position: absolute;
}

.attack-defense {
  background-color: var(--color--black);
  color: var(--color--white);
  height: 100vh;
  width: 100vw;
  z-index: var(--z-index--uniterruptable-menu);
  position: fixed;
  top: 0;
  left: 0;
}

.attack-defense__weapons {
  gap: var(--size--small);
  padding: var(--size--normal);
  flex-flow: wrap;
  display: flex;
}

.attack-defense__weapon {
  color: var(--color--white);
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.prey__flee-radius__position {
  background-color: var(--color--yellow-light);
  height: var(--size--tile);
  opacity: .6;
  width: var(--size--tile);
  z-index: var(--z-index--non-interactable);
  position: absolute;
}

.animal {
  cursor: pointer;
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
  background-color: #0000;
  border: none;
  position: absolute;
}

.animal .deer-elk {
  height: var(--size--tile);
  width: var(--size--tile);
  background-image: url("deer.78595645.png");
}

.animal .wolf-arctic {
  height: var(--size--tile);
  width: var(--size--tile);
  background-image: url("wolf.3e0e0344.png");
}

.container-overlay__action--open {
  right: 0;
  top: var(--size--normal);
  z-index: var(--z-index--control);
  position: fixed;
}

.container-overlay__item__action--select {
  cursor: pointer;
  height: var(--size--tile);
  width: var(--size--tile);
  background-color: #0000;
  border: none;
}

.container {
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
  position: absolute;
}

.container--backpack {
  background-image: url("backpack.f4e6c522.png");
}

.container--sack {
  background-image: url("sack.5ec5ac3c.png");
}

.container--trunk {
  background-image: url("trunk.6c3aa3e1.png");
}

.fire__heat-radius__position {
  background-color: var(--color--red-light);
  height: var(--size--tile);
  opacity: .6;
  width: var(--size--tile);
  z-index: var(--z-index--non-interactable);
  position: absolute;
}

.fire__action {
  cursor: pointer;
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
  background-color: #0000;
  border: none;
  position: absolute;
}

.fire {
  height: var(--size--tile);
  width: var(--size--tile);
  background-image: url("fire.a93ce572.png");
}

.fog-of-war {
  background-color: var(--color--black);
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--fog-of-war);
  position: absolute;
}

.fog-of-war--explored {
  opacity: .5;
}

.fog-of-war--unexplored {
  opacity: 1;
}

.fog-of-war--visible {
  opacity: 0;
  z-index: var(--z-index--map);
}

#interaction-overlay {
  right: 0;
  top: var(--size--normal);
  z-index: var(--z-index--control);
  position: fixed;
}

#interaction-overlay .interaction-overlay__action--open {
  height: var(--size--tile);
  width: var(--size--tile);
}

.interactable {
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--interactable);
  position: absolute;
}

.interactable.bush-bearberry {
  background-image: url("bush.d43cf8d9.png");
}

.interactable.carcass-deer-elk {
  background-image: url("carcass-deer-elk.84fa9733.png");
}

.interactable.fuel-stick {
  background-image: url("stick.5cc916a5.png");
}

.interactable.starter-matches {
  background-image: url("matches.fc98ea18.png");
}

.interactable.tinder-newspaper {
  background-image: url("newspaper.ba535e42.png");
}

.tile {
  height: var(--size--tile);
  width: var(--size--tile);
  z-index: var(--z-index--tile);
  position: absolute;
}

.tile.placeholder-empty {
  background-color: var(--color--blue-light);
}

.tile.rock-basic {
  background-image: url("rock.7aa331eb.png");
}

.tile.tree-birch {
  background-image: url("tree.bc0dc963.png");
}

.tile.water-basic {
  background-color: var(--color--blue);
}

#map {
  z-index: var(--z-index--map);
  position: relative;
}

.outfit-item {
  color: var(--color--white);
  cursor: pointer;
  height: var(--size--tile);
  width: var(--size--tile);
  background-color: #0000;
  border: none;
}

#outfit__selected-item__item {
  display: flex;
}

.outfit__selected-item__option {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.outfit__selected-item__option.selected {
  border: 1px solid var(--color--white);
}

#pause-menu__container {
  background-color: var(--color--black);
  height: 100vh;
  width: 100vw;
  z-index: var(--z-index--menu);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#pause-menu__container.open {
  display: flex;
}

#pause-menu__container > button {
  background-color: var(--color--gray-light);
  color: var(--color--black);
  cursor: pointer;
  margin: var(--size--small);
  padding: var(--size--small);
  width: 50%;
  border: none;
}

#radial-menu__fire {
  display: flex;
}

.radial-menu__fire__item {
  margin: var(--size--normal);
  display: flex;
}

.radial-menu__fire__item--empty {
  background-color: var(--color--gray);
  height: var(--size--tile);
  width: var(--size--tile);
}

@media (orientation: landscape) {
  #radial-menu__fire {
    flex-direction: row;
  }

  .radial-menu__fire__item {
    flex-direction: column;
  }
}

@media (orientation: portrait) {
  #radial-menu__fire {
    flex-direction: column;
  }

  .radial-menu__fire__item {
    flex-direction: row;
  }
}

#radial-menu {
  background-color: var(--color--black);
  color: var(--color--white);
  height: 100vh;
  width: 100vw;
  z-index: var(--z-index--menu);
  flex-direction: column;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#radial-menu.open {
  display: flex;
}

#radial-menu__header {
  padding: var(--size--small);
  flex-direction: row;
  display: flex;
}

#radial-menu__header h1 {
  flex-grow: 1;
}

#radial-menu__close {
  color: var(--color--white);
  cursor: pointer;
  padding: var(--size--small);
  background-color: #0000;
  border: none;
}

#radial-menu__content {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radial-menu__action {
  height: var(--size--tile);
}

#turns-survived {
  right: var(--size--small);
  top: var(---size--small);
  z-index: var(--z-index--hud);
  position: fixed;
}

.vital {
  background-color: var(--color--gray-light);
  font-size: var(--size--tile);
  height: 1em;
  width: 1em;
  border-radius: 50%;
  position: relative;
}

.vital:after {
  background-color: var(--color--white);
  content: " ";
  width: .84em;
  height: .84em;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: .08em;
  left: .08em;
}

.vital span {
  color: var(--color--gray);
  text-align: center;
  white-space: nowrap;
  width: 5em;
  z-index: 1;
  font-size: .2em;
  line-height: 5em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.vital .vital__value {
  clip: rect(0em, 1em, 1em, .5em);
  height: 1em;
  width: 1em;
  position: absolute;
}

.vital .vital__value .vital__value__bar, .vital .vital__value .vital__value__fill {
  border: .08em solid var(--color--red-light);
  clip: rect(0em, .5em, 1em, 0em);
  height: .84em;
  width: .84em;
  border-radius: 50%;
  position: absolute;
}

#vitals {
  bottom: var(--size--small);
  gap: var(--size--small);
  left: var(--size--small);
  z-index: var(--z-index--hud);
  display: flex;
  position: fixed;
}

@media (orientation: landscape) {
  #vitals {
    flex-direction: row;
  }
}

@media (orientation: portrait) {
  #vitals {
    flex-direction: column;
  }
}

#game__status {
  background-color: var(--color--black);
  color: var(--color--white);
  height: 100vh;
  width: 100vw;
  z-index: var(--z-index--end-screen);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#game__status > div {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color--gray-light);
}

/*# sourceMappingURL=index.c497baff.css.map */
